import { glass, theme } from "../../utils/theme";

export const styles = {
  button: {
    background: "transparent",
    boxShadow: "none",
    minWidth: "inherit",
    padding: 0,
    "&:hover": {
      boxShadow: "none",
    },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  snackbar: {
    backgroundColor: theme.palette.grays.black,
    // background: glass.background,
    // backdropFilter: glass.backdropFilter,
    // border: glass.border,
    borderRadius: `10px !important`,
    // boxShadow: glass.boxShadow,
    color: theme.palette.grays.white,
    pb: 2,
    pl: 6,
    pr: 6,
    pt: 2,
  },
};
