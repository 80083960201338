import { theme } from "../../../utils/theme";

const selectMenu = {
  alignItems: "flex-start",
  borderColor: theme.palette.grays[300],
  borderRadius: 2,
  borderStyle: "solid",
  borderWidth: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: 3,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 3,
  position: "absolute",
  zIndex: 3,
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectContainer: {
    display: "flex",
    position: "relative",
    width: "fit-content",
  },
  selectFormContainer: {
    alignItems: "center",
    color: theme.palette.grays[500],
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    ml: 3,
    mr: 3,
    pb: 1,
    pt: 1,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectFormIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    ml: 3,
    mr: 3,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectFormLabel: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 700,
    letterSpacing: 1.1,
    lineHeight: 1,
    mr: 1,
  },
  selectFormLabelContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    ml: 2,
  },
  selectMenuAnchorBottom: {
    ...selectMenu,
    "&::before": {
      content: "' '",
      position: "absolute",
      borderTop: `8px solid ${theme.palette.grays.white}`,
      borderLeft: `8px solid transparent`,
      borderRight: `8px solid transparent`,
      height: 8,
      right: 12,
      bottom: -8,
      width: 8,
    },
  },
  selectMenuAnchorTop: {
    ...selectMenu,
    "&::before": {
      content: "' '",
      position: "absolute",
      borderBottom: `8px solid ${theme.palette.grays.white}`,
      borderLeft: `8px solid transparent`,
      borderRight: `8px solid transparent`,
      height: 8,
      right: 12,
      top: -8,
      width: 8,
    },
  },
  selectOption: {
    alignItems: "center",
    color: theme.palette.grays.black,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: 2,
    paddingTop: 2,
    transition: "all 0.3s ease-in-out",
    width: "100%",
    "&:hover": {
      color: theme.palette.grays[500],
    },
  },
  selectOptionIcon: {
    color: theme.palette.primary.main,
  },
  selectOptionIconContainer: {
    alignItems: "center",
    display: "flex",
    height: 24,
    width: 24,
  },
  selectOptionLabel: {
    fontSize: theme.typography.fontSize * 0.88,
    marginRight: 2,
    whiteSpace: "nowrap",
  },
  selectOptionLabelActive: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
};
