import { theme } from "../../utils/theme";

import contact_bg from "../../assets/background/contact_bg.jpg";

export const styles = {
  background: {
    background:
      "linear-gradient(350deg, rgba(4, 104, 214, 0.34) 1.09%, rgba(225, 150, 198, 0.34) 42.33%, rgba(255, 190, 170, 0.34) 69.9%)",
    height: "100%",
    filter: "brightness(1.2)",
    right: { xs: 0, sm: 0 },
    top: { xs: 0, sm: 0 },
    position: "absolute",
    width: "100%",
    zIndex: -1,
  },
  backgroundOverlay: {
    background: `url(${contact_bg})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    bottom: 0,
    filter: "brightness(1) hue-rotate(330deg) opacity(0.08)",
    height: "100%",
    left: { xs: 0, sm: 0 },
    position: "absolute",
    width: "150%",
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    position: "relative",
  },
  bodyText: {
    color: theme.palette.grays[800],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 400,
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  button: {
    color: theme.palette.grays.white,
    transition: "all 0.5s ease-in-out",
    width: { xs: "100%", md: "fit-content" },
  },
  contactContainer: {
    alignItems: { xs: "center", md: "center" },
    borderRadius: 4,
    boxShadow:
      "0px 18px 36px -18px rgba(0,0,0,0.1),0px 30px 45px -30px rgba(50,50,93,0.25)",
    mb: { xs: 0, sm: 6 },
    mt: { xs: 0, sm: 6 },
    overflow: "hidden",
    pb: { xs: 12, sm: 14 },
    pl: { xs: 8, sm: 22 },
    pr: { xs: 8, sm: 22 },
    pt: { xs: 12, sm: 14 },
    position: "relative",
    minWidth: { xs: 0, sm: 820 },
    zIndex: 1,
  },
  contactRoot: {
    alignItems: { xs: "center", md: "center" },
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: { xs: "center", sm: "center" },
    position: "relative",
    width: { xs: "100%", sm: "100%" },
  },
  headerText: {
    fontSize: {
      xs: theme.typography.fontSize * 3,
      sm: theme.typography.fontSize * 3,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1, md: 1 },
    textAlign: { xs: "center", sm: "center" },
    textTransform: "capitalize",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  video: {
    height: "100%",
    width: "100%",
  },
  videoContainer: {
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    width: "100%",
  },
  videoPosition: {
    filter: "brightness(0.5) grayscale(0.9) hue-rotate(0deg)",
    position: "absolute",
    // right: { xs: "-120%", sm: "-20%", md: "-8%", lg: 0 },
    width: { xs: "360%", sm: "155%", md: "130%", lg: "120%" },
  },
};
