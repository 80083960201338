import { theme } from "../../utils/theme";

export const styles = {
  alertText: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.h4,
      md: theme.typography.h4,
    },
    fontWeight: 500,
    textAlign: "center",
    textTransform: "none",
  },
  button: {
    backgroundColor: theme.palette.grays.white,
    borderRadius: 1,
    color: theme.palette.primary.main,
    overflow: "hidden",
    pb: 2,
    pt: 2,
    transition: "all 0.5s ease-in-out",
    width: { xs: "100%", md: "fit-content" },
    "&:hover": {
      backgroundColor: theme.palette.grays[300],
    },
  },
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.grays.black,
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: { xs: 3, sm: 8 },
    justifyContent: "center",
    pb: 3,
    pt: 3,
    position: "absolute",
    width: "100%",
    zIndex: 99,
  },
};
