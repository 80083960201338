import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";

// import contact_video from "../../assets/videos/contact_video.mp4";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const ContactSection = () => {
  const [setRef, visible] = useInView({
    threshold: 0,
  });

  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack ref={setRef} spacing={0} sx={styles.contactRoot}>
      <SectionLayout style={{ justifyContent: "center" }}>
        <Stack spacing={0} sx={styles.contactContainer}>
          <Stack alignItems="center" spacing={12}>
            <SlideTransition appear={visible} timeout={800}>
              <Stack spacing={4} justifyContent={"center"} sx={styles.header}>
                <Typography sx={styles.headerText}>
                  {t(content.contact.header)}
                </Typography>

                <Typography sx={styles.bodyText}>
                  {t(content.contact.content)}
                </Typography>
              </Stack>
            </SlideTransition>

            {/* <SlideTransition appear={visible} timeout={900}> */}
            <Button
              color="secondary"
              onClick={() => navigate(NAV_SUBROUTES.get("CONTACT").PATH)}
              sx={styles.button}
              variant="contained"
            >
              {t(content.contact.action)}
            </Button>
            {/* </SlideTransition> */}
          </Stack>

          <Box sx={styles.background}>
            {/* <Box sx={styles.videoContainer}>
            <Box sx={styles.videoPosition}>
              <video
                autoPlay
                id="_contact_video"
                loop
                muted
                style={styles.video}
              >
                <source
                  src={matches ? contact_video : contact_video}
                  type="video/mp4"
                />
              </video>
            </Box>
          </Box> */}
            <Box sx={styles.backgroundOverlay} />
          </Box>
        </Stack>
      </SectionLayout>
    </Stack>
  );
};

export default ContactSection;
