import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import remarkGfm from "remark-gfm";
import remarkHint from "remark-hint";

import { bodyWidth } from "../../../utils/theme";

const MarkdownContainer = ({ markdown }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0 });

    fetch(markdown)
      .then((res) => res.text())
      .then((text) => setMarkdownContent(text));
  }, []);

  return (
    <Markdown
      components={{
        p: ({ node, children, ...props }) => (
          <p
            style={{
              width: "90%",
              margin: "0 auto", 
              lineHeight: "1.6", 
            }}
            {...props}
          >
            {children}
          </p>
        ),
        img: function ({ ...props }) {
          const substrings = props.alt?.split("{{");
          const alt = substrings[0].trim();

          return (
            <img
              src={props.src}
              alt={alt}
              style={{
                height: "auto",
                maxWidth: bodyWidth.maxWidth,
                width: "100%",
              }}
            />
          );
        },
      }}
      remarkPlugins={[remarkGemoji, remarkGfm, remarkHint]}
      rehypePlugins={[rehypeRaw]}
    >
      {markdownContent}
    </Markdown>
  );
};

export default MarkdownContainer;
