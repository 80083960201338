import { theme } from "../../utils/theme";

const headerActionSubtext = {
  fontSize: theme.typography.body2,
  fontWeight: 400,
  mt: 2,
  textTransform: "none",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  headerAction: {
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    mr: 3,
    mt: 5,
    maxWidth: 360,
    pb: 4,
    pl: 4,
    pr: 4,
    pt: 3,
    width: "30%",
  },
  headerActionIcon: {
    color: theme.palette.grays[500],
  },
  headerActionSubtext: {
    ...headerActionSubtext,
  },
  headerActionSubtextMuted: {
    ...headerActionSubtext,
    color: theme.palette.grays[600],
  },
  headerActionText: {
    fontSize: theme.typography * 1,
    fontWeight: 600,
  },
  headerActionTextContainer: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  headerContainer: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: 1,
    width: "100%",
  },
  headerRow: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  headerText: {
    fontSize: theme.typography.h2,
    fontWeight: 500,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "start",
    width: "100%",
  },
};
