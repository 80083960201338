import { section } from "../../utils/theme";

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  sectionBody: {
    ...section.body,
  },
  sectionContainer: {
    ...section.container,
  },
};
