import { Box } from "@mui/material";
import React from "react";

import { styles } from "./style";

const SectionLayout = ({ children, content, header, id, style }) => {
  return (
    <Box component="div" id={id} sx={{ ...styles.sectionContainer, ...style }}>
      <Box component="div" sx={styles.sectionBody}>
        {children}
      </Box>
    </Box>
  );
};

export default SectionLayout;
