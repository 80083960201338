import AgreementCreation from "../components/docPageComponents/enterpriseViews/AgreementCreation";
import GettingStarted from "../components/docPageComponents/GettingStarted";
import Introduction from "../components/docPageComponents/Introduction";
import IpCommercialization from "../components/docPageComponents/universalViews/IpCommercialization";
import IpCreation from "../components/docPageComponents/enterpriseViews/IpCreation";
import IpCreationUniversal from "../components/docPageComponents/universalViews/IpCreation";
import IpMarketing from "../components/docPageComponents/enterpriseViews/IpMarketing";
import IpMarketingUniversal from "../components/docPageComponents/universalViews/IpMarketing";
import IpMigration from "../components/docPageComponents/enterpriseViews/IpMigration";
import IpMigrationUniversal from "../components/docPageComponents/universalViews/IpMigration";
import IpMonetization from "../components/docPageComponents/universalViews/IpMonetization";
import IpSearch from "../components/docPageComponents/universalViews/IpSearch";
import IpSynchronization from "../components/docPageComponents/enterpriseViews/IpSynchronization";
import IpTransition from "../components/docPageComponents/enterpriseViews/IpTransition";
import PatentCreation from "../components/docPageComponents/enterpriseViews/PatentCreation";
import UserCreation from "../components/docPageComponents/enterpriseViews/UserCreation";
import UserCreationUniversal from "../components/docPageComponents/universalViews/UserCreation";
import UserDeletion from "../components/docPageComponents/enterpriseViews/UserDeletion";
import UserDeletionUniversal from "../components/docPageComponents/universalViews/UserDeletion";

export const ENTERPRISE_ROUTES = new Map([
  [
    "USER_CREATION",
    {
      ID: "_user_creation",
      LABEL: "User Creation",
      PATH: "enterprise-user-creation",
      VALUE: "user_creation",
      CHILDREN: [],
      ELEMENT: <UserCreation />,
    },
  ],
  [
    "USER_DELETION",
    {
      ID: "_user_deletion",
      LABEL: "User Deletion",
      PATH: "enterprise-user-deletion",
      VALUE: "user_deletion",
      CHILDREN: [],
      ELEMENT: <UserDeletion />,
    },
  ],
  [
    "IP_CREATION",
    {
      ID: "_ip_creation",
      LABEL: "IP Creation",
      PATH: "enterprise-ip-creation",
      VALUE: "ip_creation",
      CHILDREN: [],
      ELEMENT: <IpCreation />,
    },
  ],
  [
    "IP_MIGRATION",
    {
      ID: "_ip_migration",
      LABEL: "IP Migration",
      PATH: "enterprise-ip-migration",
      VALUE: "ip_migration",
      CHILDREN: [],
      ELEMENT: <IpMigration />,
    },
  ],
  [
    "IP_MARKETING",
    {
      ID: "_ip_marketing",
      LABEL: "IP Marketing",
      PATH: "enterprise-ip-marketing",
      VALUE: "ip_marketing",
      CHILDREN: [],
      ELEMENT: <IpMarketing />,
    },
  ],
  [
    "IP_TRANSITION",
    {
      ID: "_ip_transition",
      LABEL: "IP Transition",
      PATH: "enterprise-ip-transition",
      VALUE: "ip_transition",
      CHILDREN: [],
      ELEMENT: <IpTransition />,
    },
  ],
  [
    "IP_SYNCHRONIZATION",
    {
      ID: "_ip_synchronization",
      LABEL: "IP Synchronization",
      PATH: "enterprise-ip-synchronization",
      VALUE: "ip_synchronization",
      CHILDREN: [],
      ELEMENT: <IpSynchronization />,
    },
  ],
  [
    "PATENT_CREATION",
    {
      ID: "_patent_creation",
      LABEL: "Patent Creation",
      PATH: "enterprise-patent-creation",
      VALUE: "patent_creation",
      CHILDREN: [],
      ELEMENT: <PatentCreation />,
    },
  ],
  [
    "AGREEMENT_CREATION",
    {
      ID: "_agreement_creation",
      LABEL: "Agreement Creation",
      PATH: "enterprise-agreement-creation",
      VALUE: "agreement_creation",
      CHILDREN: [],
      ELEMENT: <AgreementCreation />,
    },
  ],
]);

export const UNIVERSAL_ROUTES = new Map([
  [
    "USER_CREATION",
    {
      ID: "_user_creation",
      LABEL: "User Creation",
      PATH: "universal-user-creation",
      VALUE: "user_creation",
      CHILDREN: [],
      ELEMENT: <UserCreationUniversal />,
    },
  ],
  [
    "USER_DELETION",
    {
      ID: "_user_deletion",
      LABEL: "User Deletion",
      PATH: "universal-user-deletion",
      VALUE: "user_deletion",
      CHILDREN: [],
      ELEMENT: <UserDeletionUniversal />,
    },
  ],
  [
    "IP_CREATION",
    {
      ID: "_ip_creation",
      LABEL: "IP Creation",
      PATH: "universal-ip-creation",
      VALUE: "ip_creation",
      CHILDREN: [],
      ELEMENT: <IpCreationUniversal />,
    },
  ],
  [
    "IP_MIGRATION",
    {
      ID: "_ip_migration",
      LABEL: "IP Migration",
      PATH: "universal-ip-migration",
      VALUE: "ip_migration",
      CHILDREN: [],
      ELEMENT: <IpMigrationUniversal />,
    },
  ],
  [
    "IP_MARKETING",
    {
      ID: "_ip_marketing",
      LABEL: "IP Marketing",
      PATH: "universal-ip-marketing",
      VALUE: "ip_marketing",
      CHILDREN: [],
      ELEMENT: <IpMarketingUniversal />,
    },
  ],
  [
    "IP_MONETIZATION",
    {
      ID: "_ip_monetization",
      LABEL: "IP Monetization",
      PATH: "universal-ip-monetization",
      VALUE: "ip_monetization",
      CHILDREN: [],
      ELEMENT: <IpMonetization />,
    },
  ],
  [
    "IP_COMMERCIALIZATION",
    {
      ID: "_ip_commercialization",
      LABEL: "IP Commercialization",
      PATH: "universal-ip-commercialization",
      VALUE: "ip_commercialization",
      CHILDREN: [],
      ELEMENT: <IpCommercialization />,
    },
  ],
  [
    "IP_SEARCH",
    {
      ID: "_ip_search",
      LABEL: "IP Search",
      PATH: "universal-ip-search",
      VALUE: "ip_search",
      CHILDREN: [],
      ELEMENT: <IpSearch />,
    },
  ],
]);

export const DOC_ROUTES = new Map([
  [
    "INTRODUCTION",
    {
      ID: "_introduction",
      LABEL: "Introduction",
      PATH: "introduction",
      VALUE: "introduction",
      CHILDREN: [],
      ELEMENT: <Introduction />,
    },
  ],
  [
    "GETTING_STARTED",
    {
      ID: "_getting_started",
      LABEL: "Getting Started",
      PATH: "getting-started",
      VALUE: "getting-started",
      CHILDREN: [],
      ELEMENT: <GettingStarted />,
    },
  ],
  [
    "ENTERPRISE",
    {
      ID: "_enterprise_ipms",
      LABEL: "Enterprise IPMS",
      PATH: "enterprise-ipms",
      VALUE: "enterprise-ipms",
      CHILDREN: [...ENTERPRISE_ROUTES],
    },
  ],
  [
    "UNIVERSAL",
    {
      ID: "_universal_ipcp",
      LABEL: "Universal IPCP",
      PATH: "universal-ipcp",
      VALUE: "universal-ipcp",
      CHILDREN: [...UNIVERSAL_ROUTES],
    },
  ],
]);

export const NAV_SUBROUTES = new Map([
  [
    "OVERVIEW",
    {
      ID: "_overview",
      LABEL: "Overview",
      PATH: "#_overview",
      VALUE: "overview",
      CHILDREN: [],
    },
  ],
  [
    "VALUE",
    {
      ID: "_value",
      LABEL: "Our Customer",
      PATH: "#_value",
      VALUE: "value",
    },
  ],
  [
    "PRODUCT",
    {
      ID: "_product",
      LABEL: "Product",
      PATH: "#_product",
      VALUE: "product",
      CHILDREN: [],
    },
  ],
  [
    "ABOUT",
    {
      ID: "_about",
      LABEL: "About Us",
      PATH: "#_about",
      VALUE: "about",
      CHILDREN: [],
    },
  ],
  [
    "CONTACT",
    {
      ID: "_contact",
      LABEL: "Contact Us",
      PATH: "contact",
      VALUE: "contact",
      CHILDREN: [],
    },
  ],
  [
    "DOCS",
    {
      ID: "",
      LABEL: "Docs",
      PATH: "docs",
      VALUE: "docs",
      CHILDREN: [DOC_ROUTES],
    },
  ],
  [
    "EVENTS_LIST",
    {
      ID: "_events",
      LABEL: "Events",
      PATH: "events",
      VALUE: "events",
      CHILDREN: [],
    },
  ],
  [
    "EVENT",
    {
      LABEL: "",
      PATH: "events/:eventPath",
    },
  ],
]);

export const NAV_ROUTES = new Map([
  [
    "HOME",
    {
      ID: "_home",
      LABEL: "Home",
      PATH: ":lang",
      PATHNAME: "",
      VALUE: "home",
      CHILDREN: [...NAV_SUBROUTES],
    },
  ],
]);
