import { DOC_ROUTES, NAV_ROUTES, NAV_SUBROUTES } from "./routes";

export const NAV_OPTIONS = new Map([
  ["OVERVIEW", NAV_SUBROUTES.get("OVERVIEW")],
  ["VALUE", NAV_SUBROUTES.get("VALUE")],
  ["PRODUCT", NAV_SUBROUTES.get("PRODUCT")],
  ["ABOUT", NAV_SUBROUTES.get("ABOUT")],
  ["DOCS", NAV_SUBROUTES.get("DOCS")],
  ["EVENTS_LIST", NAV_SUBROUTES.get("EVENTS_LIST")],
  ["CONTACT", NAV_SUBROUTES.get("CONTACT")],
]);

export const DOCS_OPTIONS = new Map([
  ["INTRODUCTION", DOC_ROUTES.get("INTRODUCTION")],
  ["GETTING_STARTED", DOC_ROUTES.get("GETTING_STARTED")],
  ["ENTERPRISE", DOC_ROUTES.get("ENTERPRISE")],
  ["UNIVERSAL", DOC_ROUTES.get("UNIVERSAL")],
]);

export const LANGUAGES_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      KEY: "en",
    },
  ],
  [
    "CHINESE",
    {
      LABEL: "中文",
      KEY: "zh-cn",
    },
  ],
]);
