import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
export const theme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 16,
    fontFamily: [
      "Montserrat",
      // "Kulim Park",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    useNextVariants: true,
  },
  palette: {
    background: {
      default: "#fff",
      dark: "#030A0E",
      glass: "rgba(255, 255, 255, 0.2)",
    },
    primary: {
      main: "#AC539F",
      light: "#BB6BD9",
      gradient: "linear-gradient(90deg, #7C1CFD -5.73%, #139AF6 88.78%)",
    },
    secondary: {
      main: "#0F62FE",
      light: "#e1f2ff",
    },
    accent: {
      main: "#eb6339",
    },
    danger: {
      main: red.A400,
    },
    grays: {
      white: "#fff",
      100: "#f6f8fa",
      200: "#f0f2f5",
      300: "#e4e6ef",
      400: "#b6b6c4",
      500: "#a2a6b8",
      600: "#7e8299",
      700: "#5e6278",
      800: "#4f5058",
      900: "#404254",
      1000: "#121526",
      1100: "#07080f",
      black: "#0D0D0D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          color: "#fff",
          fontSize: "14px",
          fontWeight: 700,
          letterSpacing: 2,
          paddingBottom: 12,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 12,
          textTransform: "none",
          transition: "all 0.5s ease-in-out",
        },
        contained: {
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          },
        },
        outlined: {
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            border: `1px solid ${"#e4e6ef"}`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {},
      },
    },
  },
});

export const appBar = {
  height: 50,
  width: "100%",
};

export const bodyWidth = {
  maxWidth: 1296,
  width: "95%",
};

export const section = {
  container: {
    alignItems: "start",
    display: "flex",
    justifyContent: "center",
    pb: { xs: 6, md: 20 },
    pt: { xs: 12, md: 20 },
    position: "relative",
    width: { xs: "100%", md: "100%", lg: bodyWidth.maxWidth },
  },
  body: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    pl: { xs: 4, sm: 8 },
    pr: { xs: 4, sm: 8 },
    width: "100%",
    zIndex: 1,
  },
};

export const glass = {
  background: "rgba(255, 255, 255, 0.2)",
  backdropFilter: "blur(5px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  boxShadow: "0px 4px 12px rgba(184, 184, 184, 0.25)",
};
