import { appBar, bodyWidth, theme } from "../../../utils/theme";

const nav = {
  alignItems: "center",
  backdropFilter: "none",
  borderBottomColor: theme.palette.grays.white,
  borderBottomStyle: "solid",
  borderBottomWidth: 0,
  color: theme.palette.grays.white,
  display: "flex",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  navContained: {
    ...nav,
    backgroundColor: theme.palette.grays.white,
    // boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
  },
  navDefault: {
    ...nav,
    backdropFilter: "none",
    backgroundColor: "transparent",
    color: theme.palette.grays.white,
  },
  toolbar: {
    height: appBar.height,
    maxWidth: bodyWidth.maxWidth,
    paddingBottom: theme.spacing(0),
    paddingLeft: `${theme.spacing(0)} !important`,
    paddingRight: `${theme.spacing(0)} !important`,
    paddingTop: theme.spacing(0),
    width: bodyWidth.width,
  },
};
