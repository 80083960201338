import { Box } from "@mui/material";
import React, { useContext } from "react";

import Alert from "../../components/Alert";
import About from "../../components/About";
import ContactSection from "../../components/ContactSection";
import Hero from "../../components/Hero";
import Overview from "../../components/Overview";
import Product from "../../components/Product";
import Value from "../../components/Value";

import { GlobalContext } from "../../contexts/GlobalContext";

import { styles } from "./styles";

const HomePage = () => {
  const { state, dispatch } = useContext(GlobalContext);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {/* <Alert /> */}

      <Hero />

      <Overview />

      <Value />

      <Product />

      <About />

      <ContactSection />
    </Box>
  );
};

export default HomePage;
