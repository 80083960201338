import { Box } from "@mui/material";
import i18n from "i18next";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import CustomSnackbar from "../components/CustomSnackbar";
import Footer from "../components/Footer";
import Loader from "../components/LoadingScreen";
import Nav from "../components/navComponents/Nav";

import { saveLanguage } from "../i18n-locales/i18n-translation";
import { NAV_ROUTES } from "../utils/routes";

const OverallLayout = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { lang } = useParams();

  useEffect(() => {
    if (lang === i18n.language) return;

    /** Save language base on URL. Only when language choice is not in localStorage */
    saveLanguage(lang || i18n.language);

    /** Route to Home page if URL does not include language i.e. "/" */
    if (!lang) {
      navigate(`${NAV_ROUTES.get("HOME").PATHNAME}/${i18n.language}/`);
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      handleScrollToSection(location.hash);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleScrollToSection = (hash) => {
    document.querySelector(hash)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: { xs: "hidden", sm: "hidden", md: "clip" },
        position: "relative",
        width: "100%",
      }}
    >
      <Nav />

      <Outlet />

      <Footer />

      <CustomSnackbar />

      <Loader />
    </Box>
  );
};

export default OverallLayout;
