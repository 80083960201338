import { Box, Divider, Grow, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MdOutlineArrowForwardIos } from "react-icons/md";
import { TbPointFilled } from "react-icons/tb";

import { styles } from "./style";

const EventsListItem = ({ data, index, visible }) => {
  const [mouseOver, setMouseOver] = useState(false);

  return (
    <Grow key={index} in={visible} timeout={1000 + index * 500}>
      <Stack
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        spacing={0}
        sx={styles.item}
      >
        <Link to={data.path} style={{ textDecoration: "none", width: "100%" }}>
          <Box sx={styles.itemImageContainer}>
            <Box
              sx={{
                ...styles.itemImage,
                background: `url(${data.thumbnail})`,
              }}
            ></Box>
          </Box>

          <Stack
            alignItems="start"
            justifyContent={"start"}
            sx={styles.itemBody}
          >
            <Stack alignItems="start" justifyContent={"start"} spacing={2}>
              <Typography
                sx={mouseOver ? styles.itemHeaderActive : styles.itemHeaderInactive}
              >
                {data.title}
              </Typography>

              <Stack
                alignItems="center"
                direction="row"
                justifyContent={"start"}
                spacing={1}
              >
                <Typography sx={styles.itemText}>{data.venue}</Typography>

                <TbPointFilled size="0.8em" style={styles.itemTextDivider} />

                <Typography sx={styles.itemText}>{data.date}</Typography>
              </Stack>
            </Stack>

            {/* <Box sx={styles.itemAction}>
              {data.action}
              <MdOutlineArrowForwardIos size="0.9em" />
              </Box> */}
            <Divider flexItem />
          </Stack>
        </Link>
      </Stack>
    </Grow>
  );
};

export default EventsListItem;
